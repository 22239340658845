export type ExperimentName = `experiment.${string}`
type Experiment = {
  defaultValue: 'noVariation'
  urlPatterns: Array<string>
}

/**
 * This dictionary holds the configuration for all the experiments currently
 * being assigned by the experiments worker.
 *
 * @property defaultValue - Must be set to noVariation for all of them.
 * @property urlPatterns - Allows you to scope each experiment to their
 * associated set of pages. Those patterns are used on the worker side
 * to know if the experiment will be used to process the current request
 * or not. It helps us improving our cache hit ratio, and thus the
 * overall server-side performance.
 */
export const config = {
  'experiment.globalAA': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
  'experiment.homeAA': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/?$' /* Home page */],
  },
  'experiment.landingAA': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/l/.*' /* Landing page */],
  },
  'experiment.productAA': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.checkoutAA': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/f2/.*' /* Checkout pages */],
  },
  'experiment.homeCMSPageName': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/?$' /* Home page - permanent AB test */],
  },
  'experiment.goodDealsEventPageName': {
    defaultValue: 'noVariation',
    urlPatterns: [
      '^/[a-z]{2}-[a-z]{2}/e/.*$' /* Event pages - permanent AB test - GoodDeal */,
    ],
  },
  'experiment.recyclingEventPageName': {
    defaultValue: 'noVariation',
    urlPatterns: [
      '^/[a-z]{2}-[a-z]{2}/e/.*$' /* Event pages - permanent AB test - Recycling */,
    ],
  },
  'experiment.holidaysEventPageName': {
    defaultValue: 'noVariation',
    urlPatterns: [
      '^/[a-z]{2}-[a-z]{2}/e/.*$' /* Event pages - permanent AB test - Holidays */,
    ],
  },
  'experiment.homeBuybackPageName': {
    defaultValue: 'noVariation',
    urlPatterns: [
      '^/[a-z]{2}-[a-z]{2}/buyback/.*' /* All Buyback Pages - permanent AB test */,
    ],
  },
  'experiment.ppSplitPriceEuropeVersion': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.ppSplitPriceUSVersion': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.serviceFeePriceGroup24': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },

  'experiment.ppBuybackDropdown': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.visibleByVerizonIteration': {
    /** Variations:
     * withCarouselLayout
     * withCardLayout
     */
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/f2/.*' /* Checkout pages */],
  },
  'experiment.ppBouyguesOffersFilter': {
    defaultValue: 'noVariation',
    /** Variations:
     * withoutB&YOU1999Subsidy150
     * withoutB&YOU1999Subsidy120
     *  */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.ppDeliveryBeforeXmas': {
    defaultValue: 'noVariation',
    /** Variations:
     * withDeliveryBeforeXmas
     * withoutDeliveryBeforeXmas
     *  */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.mixAndMatchMacbooks': {
    defaultValue: 'noVariation',
    /** Variations:
     * withoutMixAndMatch
     * withMixAndMatch
     */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.ppMixAndMatchEverywhere': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.insuranceMonthlySubscription': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/f2/.*' /* Checkout pages */],
  },
  'experiment.warrantyExtensionOffer': {
    defaultValue: 'noVariation',
    /** Variations:
     *  withWarrantyExtension
     * 	withoutWarrantyExtension
     */
    urlPatterns: [
      '^/[a-z]{2}-[a-z]{2}/f2/.*',
      /* Checkout pages */
    ],
  },
  // https://backmarket.atlassian.net/wiki/spaces/CHEC/pages/4169695455/PRD+Insurance+position+in+the+checkout
  'experiment.insurancesInAddToCartModal': {
    defaultValue: 'noVariation',
    /** Variations:
     *  notDisplayed
     * 	withCartReminder
     * 	withoutCartReminder
     */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
  'experiment.insuranceBenefitList': {
    defaultValue: 'noVariation',
    /** Variations:
     * insuranceBenefitFullList
     * insuranceBenefitShortList */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/f2/.*' /* Checkout pages */],
  },
  'experiment.cartContentCTA': {
    defaultValue: 'noVariation',
    /** Variations:
     * cartContentNextStep
     * cartContentCheckout */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/f2/.*' /* Checkout pages */],
  },

  'experiment.fakeBytelMobilePlan': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
  'experiment.killOneyFr': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
  'experiment.enableClearpayUK': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages (in the footer) */],
  },
  'experiment.enableKlarnaIT': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages (in the footer) */],
  },
  'experiment.ppPremiumGrade': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.ppImmersiveStories': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.ppRealGradePicture': {
    defaultValue: 'noVariation',
    /** Variations:
     * withoutRealPicture
     * withRealPicture */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.discountedPriceInPP': {
    defaultValue: 'noVariation',
    /** Variations:
     * withoutDiscountedSwap
     * withDiscountedSwap */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
  'experiment.ppHideOutOfStockPickers': {
    defaultValue: 'noVariation',
    /** Variations:
     * hideOutOfStockPickers
     * showOutOfStockPickers */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.newNavigation': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
  'experiment.displayEditedReviews': {
    defaultValue: 'noVariation',
    /** Variations:
     * withoutEditedReviews
     * withEditedReviews */
    urlPatterns: [
      '^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */,
      '^/[a-z]{2}-[a-z]{2}/l/.*' /* PLP page */,
      '^/[a-z]{2}-[a-z]{2}/s/.*' /* Seller page */,
      '^/[a-z]{2}-[a-z]{2}/r/.*' /* Reviews pages */,
    ],
  },
  'experiment.photosInReview': {
    defaultValue: 'noVariation',
    /** Variations:
     * withoutPhotos
     * withPhotosDefaultRelevance
     * withPhotosNewRelevance */
    urlPatterns: [
      '^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */,
      '^/[a-z]{2}-[a-z]{2}/l/.*' /* PLP page */,
      '^/[a-z]{2}-[a-z]{2}/s/.*' /* Seller page */,
      '^/[a-z]{2}-[a-z]{2}/r/.*' /* Reviews pages */,
    ],
  },
  'experiment.paymentFormVersion': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/f2/payment*' /* Payment page */],
  },
  'experiment.buybackHeader': {
    /** Variations:
     * withoutMinimal
     * withMinimal */
    defaultValue: 'noVariation',
    urlPatterns: [
      '^/[a-z]{2}-[a-z]{2}/buyback-funnel/.*' /* Buyback funnel pages */,
    ],
  },
  'experiment.reduceHeader': {
    defaultValue: 'noVariation',
    /** Variations:
     * big
     * small */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
  'experiment.removeListingFacets': {
    /** Variations:
     * with
     * without */
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/l/.*' /* PLP page */],
  },
  'experiment.newReassuranceBanner': {
    /** Variations:
     * new
     * old */
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/l/.*' /* PLP page */],
  },
  'experiment.cookieBanner': {
    defaultValue: 'noVariation',
    /** Variations:
     * refuseAll
     * necessaryOnly
     * necessaryOnlyAndButtons */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
  'experiment.discountedPriceInProductCard': {
    defaultValue: 'noVariation',
    /** Variations:
     * withoutDiscountedPrice
     * withDiscountedPrice
     * withDiscountedPriceOnlyAfter */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
  'experiment.checkoutContentCTA': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/f2/.*' /* Checkout pages */],
  },
  'experiment.cartStockTagPrice': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/f2/.*' /* Checkout pages */],
  },
  'experiment.removeHPC': {
    /** Variations:
     * with
     * without */
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/l/.*' /* PLP page */],
  },
} satisfies Record<ExperimentName, Experiment>
